// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { motion } from "framer-motion"
import supportImg from "../images/home/support-img.svg"

const SupportBanner = ({ siteTitle }) => (
  <section className="section--color">
    <div className="container" style={{ padding: "2em" }}>
      <div className="grid grid-2">
        <div
          className="card-content--left support-content"
          style={{ maxWidth: "500px" }}
        >
          <h2 style={{ marginBottom: "0.5em", fontSize: "1.2em" }}>
            We’ll respond super fast if you need any help
          </h2>
          <p style={{ marginBottom: "1em" }}>
            Contact us and one of our experts will get back to you right away.
            Expect a response within 2 business hours.
          </p>
          <p>
            Determined to figure it out yourself? Browse or search our
            documentation for answers and detailed guides.
          </p>
          <div className="btn-container d-f">
            <motion.div whileHover={{ scale: 0.9 }} whileTap={{ scale: 1 }}>
              <a
                className="btn btn--outline"
                href="mailto:support@readyfive.io"
                style={{ marginTop: "1em", marginLeft: "0" }}
              >
                Contact us anytime
              </a>
            </motion.div>
            <motion.div whileHover={{ scale: 0.9 }} whileTap={{ scale: 1 }}>
              <a
                className="btn btn--outline"
                href="/docs/"
                style={{ marginTop: "1em" }}
              >
                Browse the docs
              </a>
            </motion.div>
          </div>
        </div>
        <img
          src={supportImg}
          alt="Illustration of speech bubble"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  </section>
)

SupportBanner.propTypes = {
  siteTitle: PropTypes.string,
}

SupportBanner.defaultProps = {
  siteTitle: ``,
}

export default SupportBanner

import "../components/styles/blob.scss"

import AWSLogo from "../images/integrations/Aws.svg"
import Layout from "../components/layout"
import React from "react"
import Seo from "../components/seo"
// Components
import SupportBanner from "../components/support"
import bugsnag from "../images/integrations/Bugsnag.svg"
import mainHomeImage from "../images/home/home-hero-image.png"
import scheduleDashboard from "../images/home/schedule-image.png"
import gradientMainHome from "../images/home/bg-gradient.svg"
import overridePhone from "../images/home/override-ui.png"
import abstractSqBg from "../images/home/abstract-square-bg.svg"
import integrationIcon from "../images/home/integrate-check.svg"
import escalationPolicy from "../images/home/platform-escalation.png"
// Icons
import iconMoon from "../images/home/icon-moon.png"
import iconCal from "../images/home/icon-calendar.svg"
import iconBell from "../images/home/icon-notification-bell.png"
import iconPersonCheck from "../images/home/icon-person-check.svg"
import iconSettings from "../images/home/icon-settings.svg"
// Integrations Logos
import datadog from "../images/integrations/Datadog.svg"
import googleCloudLogo from "../images/integrations/GoogleCloud.png"
import integrationsBG from "../images/home/integrations-bg.svg"
import microsoftLogo from "../images/integrations/Microsoft.png"
import { motion } from "framer-motion"
import newrelic from "../images/integrations/NewRelic.svg"
import pingdom from "../images/integrations/Pingdom.svg"
import slackLogo from "../images/integrations/Slack_RGB_White.svg"
import uptimeRobotLogo from "../images/integrations/UptimeRobot.svg"

import FeatureTabs from "../components/feature-tabs"

import Slide1 from "../images/never-miss-page/Image-1.png"
import Slide2 from "../images/never-miss-page/Image-2.png"

const IndexPage = () => (
  <Layout className="home">
    <Seo
      title="Ready Five · Manage on call, alert your team, respond faster."
      description="Ready Five incident management software gives your team the tools to notify the right people so you can quickly restore service."
    />
    <div>
      <section
        style={{
          zIndex: "10",
          position: "relative",
        }}
      >
        <div className="container top-container" style={{ paddingBottom: "0" }}>
          <div className="main-hero--section">
            <div
              style={{ 
                zIndex: '20',
                position: "relative",
              }}
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
              className="content"
            >
              <h1 className="main-title">
                Avoid costly disruptions to your business
              </h1>
              <p
                style={{
                  maxWidth: "520px",
                  textAlign: "center",
                  margin: "1em 0",
                  fontSize: "1.2rem",
                  lineHeight: "32px",
                }}
              >
                Ready Five provides fast and friendly incident management
                software, allowing flexible on-call scheduling and alerting so
                your teams can respond to incidents quickly and easily.
              </p>
              <motion.div
                whileHover={{ scale: 0.90 }}
                whileTap={{ scale: 1 }}
                className="mt-2 btn--container"
              >
                <a
                  className="btn btn--primary"
                  href="https://app.readyfive.io/signup"
                >
                  Start a free trial
                </a>
              </motion.div>
            </div>
            <div className="main-hero-image-container">
              <img
                src={mainHomeImage}
                alt="ReadyFive Schedule Dashboard with phone alert"
              />
            </div>
            <img
              className="graidentHome"
              src={gradientMainHome}
              alt="Gradient Glows"
            />
          </div>
        </div>
      </section>
      <section
        style={{
          background: "linear-gradient(180deg, #5A4BF8 0%, #B352FF 100%)",
          zIndex: "30",
          position: "relative",
          padding: "12em 0 0 0",
        }}
      >
        <div className="container">
          <h2 className="second-title">
            Don’t miss out on life just because you’re on call.
          </h2>
          <FeatureTabs
            className="product-tabs columns product-tabs_home"
            tabs={[
              {
                text: "Customize your notification preferences once and carry your phone like you normally do. We’ll take care of the rest.",
                image: Slide1,
              },
              {
                text: "Setup Critical Alerts to bypass Do Not Disturb with adjustable alert volumes and feel confident you're always reachable.",
                image: Slide2,
              },
            ]}
          />
        </div>
      </section>
      <section>
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              maxWidth: "560px",
              margin: "4em auto 3em auto",
            }}
          >
            Make an on-call schedule that works for you and your team
          </h2>
          <div
            className="grid grid-3 grid-icons"
            style={{
              maxWidth: "1100px",
              margin: "auto auto 3em",
              alignItems: "flex-start",
            }}
          >
            <div className="content">
              <img
                src={iconSettings}
                alt="Icon of settings dials"
                style={{
                  marginBottom: "1em",
                  height: "43px",
                }}
              />
              <h5>Configurable for each team</h5>
              <p>
                Set a schedule and forget it or customize things week by week,
                it's up to you. Cover for others and trade shifts with ease.
              </p>
            </div>
            <div className="content">
              <img
                src={iconCal}
                alt="Icon of calendar"
                style={{
                  marginBottom: "1em",
                  height: "43px",
                }}
              />
              <h5>Available at a glance</h5>
              <p>
                Keep track of your shifts with customizable notification
                preferences or subscribe to your schedule using your favorite
                calendar app.
              </p>
            </div>
            <div className="content">
              <img
                src={iconPersonCheck}
                alt="Icon of a person with a checkmark"
                style={{
                  marginBottom: "1em",
                  height: "43px",
                }}
              />
              <h5>Simple shift setup</h5>
              <p>
                Define on-call rotations for the way you work with a few clicks.
                Rest easy knowing you have 24x7 operational coverage.
              </p>
            </div>
          </div>
          <img
            style={{
              maxWidth: "1125px",
              width: "100%",
              margin: "0 auto 3em",
              position: "relative",
            }}
            src={scheduleDashboard}
            alt="Default schedule interface showing a timeline of users"
          />
        </div>
      </section>
      <section className="section--lte" style={{ paddingBottom: "0" }}>
        <div className="container grid grid-2" style={{ position: "relative" }}>
          <div className="section--content">
            <h2
              style={{
                borderBottom: "2px solid rgba(0,0,0,0.4)",
                padding: "0 0 0.5em 0",
              }}
            >
              Take a vacation, or even just a night off
            </h2>
            <ul className="icon-list">
              <li>
                <img
                  src={iconMoon}
                  alt="Icon image of a moon"
                  style={{ maxWidth: "30px" }}
                />
                <p>
                  Taking a break from work? Quickly override all or part of your
                  shift so it forwards to someone else. Relax during time off
                  knowing you won't be bothered.
                </p>
              </li>
              <li>
                <img
                  src={iconBell}
                  alt="Icon image of a bell"
                  style={{ maxWidth: "20px", paddingRight: "10px" }}
                />
                <p>
                  Forget to request time off, unexpectedly end up without cell
                  coverage, or otherwise unable to respond? Escalation rules
                  have you covered - we'll contact others on your team based on
                  timings that you specify if someone doesn't respond in time.
                </p>
              </li>
            </ul>
          </div>
          <div className="image-container">
            <img
              src={overridePhone}
              alt="UI on the schedule timeline for overriding the schedule."
              className="overridePhoneImg"
              style={{
                zIndex: "30",
                maxWidth: "650px",
                position: "relative",
                bottom: "-20px",
                left: "-50px",
              }}
            />
          </div>
          <img
            src={abstractSqBg}
            alt="square abstract background"
            className="squareBG"
            style={{
              position: "absolute",
              right: "0px",
              bottom: "20px",
              width: "1012px",
              height: "100%",
              zIndex: "10",
            }}
          />
        </div>
      </section>
      <section
        className="section--color section--testimonials"
        style={{ padding: "1em 0 4em 0" }}
      >
        <div className="container">
          <div className="section--header" style={{ marginBottom: "3em" }}>
            <h2 style={{ color: "#ffffff", marginTop: "3em" }}>
              Don't just take our word for it
            </h2>
            <p>People love Ready Five. Here are some of their kind words.</p>
          </div>
          <div className="grid grid-3">
            <div
              className="card-lte card-img-medium fd-c ai-fe"
              style={{ padding: "0 2.8em" }}
            >
              <p className="as-fs">
                I just created my first override in Ready Five and it was
                🙌👌🧘‍♀️. Thank you for bringing this magic into our lives.
              </p>
              <div className="signature">
                <p className="sig-name">Allie</p>
                <p className="sig-title">Product Manager / Designer</p>
              </div>
            </div>
            <div
              className="card-lte card-img-medium fd-c ai-fe"
              style={{ padding: "0 2.8em" }}
            >
              <p className="as-fs">
                Clear notification settings in @getreadyfive helped me sleep
                without worry. The critical alert support is 🔥.
              </p>
              <div className="signature">
                <p className="sig-name">Wassim</p>
                <p className="sig-title">Developer</p>
              </div>
            </div>
            <div
              className="card-lte card-img-medium fd-c ai-fe"
              style={{ padding: "0 2.8em" }}
            >
              <p className="as-fs">
                ...it's everything we want, and none of the crap we don't.
              </p>
              <div className="signature">
                <p className="sig-name">Mark</p>
                <p className="sig-title">Platform Manager</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section--dark section--integrations d-f">
        <div className="container" style={{ width: "100%" }}>
          <div className="section--header">
            <img
              src={integrationIcon}
              alt="Atom icon with checkmark"
              style={{ maxWidth: "120px" }}
            />
            <h2 className="heading-decor">
              There are endless providers out there, we work with all of them
            </h2>
            <p style={{ maxWidth: "600px" }}>
              We're constantly adding official integrations, but any service
              that can send a webhook, make an HTTP request, or send an email is
              supported today.
            </p>
          </div>
          <div className="grid grid-3">
            <div className="card-lte card-img-medium">
              <img src={slackLogo} alt="Slack" />
            </div>
            <div className="card-lte card-img-small">
              <img src={AWSLogo} alt="AWS" />
            </div>
            <div className="card-lte card-img-large">
              <img src={microsoftLogo} alt="Microsoft" />
            </div>
            <div className="card-lte card-img-medium">
              <img src={googleCloudLogo} alt="Google Cloud" />
            </div>
            <div className="card-lte card-img-large">
              <img src={bugsnag} alt="Bugsnag" />
            </div>
            <div className="card-lte card-img-small">
              <img src={datadog} alt="Datadog" />
            </div>
            <div className="card-lte card-img-medium">
              <img src={newrelic} alt="New Relic" />
            </div>
            <div className="card-lte card-img-medium">
              <img src={pingdom} alt="Pingdom" />
            </div>
            <div className="card-lte card-img-medium">
              <img src={uptimeRobotLogo} alt="UptimeRobot" />
            </div>
          </div>
          <div className="card-lte call-out-card">
            <p>
              Want an official integration with a service we don't yet offer?
              We're happy to add it.
            </p>
            <motion.div whileHover={{ scale: 0.9 }} whileTap={{ scale: 1 }}>
              <a className="btn" href="mailto:support@readyfive.io">
                Get in touch
              </a>
            </motion.div>
          </div>
        </div>
      </section>
      <SupportBanner />
      <section className="section--lte" style={{ paddingBottom: "0" }}>
        <div className="container grid grid-2">
          <div className="image-container image-container--footer">
            <img
              src={escalationPolicy}
              alt="UI showing escalation policies for team"
              style={{
                maxWidth: "700px",
                position: "relative",
                bottom: "-23px",
              }}
            />
          </div>
          <div className="section--content">
            <h2>Get started for free</h2>
            <p>
              Start scheduling, alerting, and integrating with your existing
              services in minutes. Try it now, free for 14 days. Sign up now,
              cancel anytime, no credit card required.
            </p>
            <motion.div
              whileHover={{ scale: 0.98 }}
              whileTap={{ scale: 1 }}
              className="mt-2"
            >
              <a
                className="btn btn--primary"
                href="https://app.readyfive.io/signup"
              >
                Start a free trial
              </a>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default IndexPage
